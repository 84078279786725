
//火车票原订单列表查询
const __request = require(`./__request/__request_contentType_json`);
const consumer_trains_order_trainOrderListCenter = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/consumer/trains/order/trainOrderListCenter',
    data: pParameter
  };
  return new Promise(function(resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_trains_order_trainOrderListCenter;

