import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/pagination/index.vue'
import moment from 'moment'


// import consumer_admin_flight_order_download from '@/lib/data-service/haolv-default/consumer_admin_flight_order_download'

//火车票原订单列表
import consumer_trains_order_trainOrderListCenter from '@/lib/data-service/haolv-default/consumer_trains_order_trainOrderListCenter'
//火车票原订单列表分类数量
import consumer_trains_order_trainOrderCountByStatus from '@/lib/data-service/haolv-default/consumer_trains_order_trainOrderCountByStatus'
//火车票原订单下载
import consumer_trains_order_trainOrderListCenterExport from '@/lib/data-service/haolv-default/consumer_trains_order_trainOrderListCenterExport'
import consumer_orderNo_center_userAllowBookingDepts from '@/lib/data-service/haolv-default/consumer_orderNo_center_userAllowBookingDepts'
export default {
    data() {
        return {
            scheduledWayList: [
                {value: 3, label: '全部'},
                {value: 1, label: '预定'},
                {value: 2, label: '抢票'}
            ],
            scheduledWay:'',
            loading: false,
            dropdown: '',
            orderStatusData: 0,
            searchForm: {
                gmtEnd: '',
                gmtStart: '',
                deptIds: [], //
                deptIdList: [],
                passengerName: '', // 出行人姓名
                orderNo: '',   // 订单号
                orderStatus: 0, //订单状态
                orderType: 3, //1预定2抢票3全部
                currentPage: 1,   //当前页码
                pageSize: 10,   //每页记录条数
            },
            applyTime: [],
            deptList: [],
            totalCount: 0,   //总条数

            activeName: '0',
            tabList: [
                // // 订单状态  0=全部 10=超标审批中 2=待支付 3=待出票 4=已出票 5=出票失败 6=已取消
                {
                    name: "0",
                    label: "全部",
                    value: 0
                },
                {
                    name: "10",
                    label: "超标审批中",
                    value: 0
                },
                {
                    name: "2",
                    label: "待支付",
                    value: 0
                },
                {
                    name: "3",
                    label: "待出票",
                    value: 0
                },
                {
                    name: "4",
                    label: "已出票",
                    value: 0
                },
                {
                    name: "5",
                    label: "出票失败",
                    value: 0
                },
                {
                    name: "6",
                    label: "已取消",
                    value: 0
                }
            ],
            orderList: [],
            canDownload: true,
        }
    },
    components: {
        SearchBox,
        Pagination
    },
    activated() {
        this.getDeptList();
        this.getOrderList();
    },
    watch: {
        applyTime: {
            handler (val) {
                if (val && val.length) {
                    this.searchForm.gmtStart = moment(val[0]).format('YYYY-MM-DD 00:00:00');
                    this.searchForm.gmtEnd = moment(val[1]).format('YYYY-MM-DD 23:59:59');
                } else {
                    this.searchForm.gmtEnd = '';
                    this.searchForm.gmtStart = '';
                }
            }
        },
    },
    methods: {
        changeDept() {
            let deptId = [];
            this.searchForm.deptIdList.forEach(value => {
                value.forEach(value1 => {
                    deptId.push(value1);
                })
            })
            this.searchForm.deptIds = deptId;
        },
        // 获取部门信息
        getDeptList() {
            consumer_orderNo_center_userAllowBookingDepts().then(res => {
                this.deptList = res.datas.deptList;
            })
        },
        // 获取订单列表
        getOrderList() {
            this.loading = true;
            this.searchForm.orderStatus = parseInt(this.activeName);
            let deptId = [];
            this.searchForm.deptIdList.forEach(value => {
                value.forEach(value1 => {
                    deptId.push(value1);
                })
            })
            this.searchForm.deptIds = deptId;
            consumer_trains_order_trainOrderListCenter(this.searchForm).then(res => {
                let orderList = res.datas.list;
                //出行人预处理
                orderList.forEach(value => {
                    let psgNameList = [];
                    if (value.psgName) {
                        psgNameList = value.psgName.split(',')
                    }
                    value.psgNameList = psgNameList
                });
                this.orderList = orderList;
                this.totalCount = res.datas.totalCount
            }).catch(err => {
                this.orderList = [];
                this.totalCount = 0
            }).finally(() => {
                this.loading = false
            });

            this.postTrainOrder();
        },
        //获取订单各状态数量
        postTrainOrder(){
            //预处理订单个状态数量
            let dataForm = this.searchForm;
            dataForm.orderStatus = 0;

            consumer_trains_order_trainOrderCountByStatus(dataForm).then(res => {
                res.datas.trainOrderCountVoList.forEach((item,index) => {
                    this.tabList.forEach((tabItem,tabIndex) => {
                        if (item.orderStatus == tabItem.name) {
                            tabItem.value = item.orderCount;
                        }
                    })
                });
            })
        },
        // 搜索按钮
        onSearch() {
            this.searchForm.currentPage = 1;
            this.getOrderList();
        },
        // 重置按钮--TRAIN
        onReset() {
            this.searchForm = {
                gmtEnd: '',
                gmtStart: '',
                deptIds: [], //
                deptIdList: [],
                passengerName: '', // 出行人姓名
                orderNo: '',   // 订单号
                orderStatus: 0,
                orderType: 3,
                currentPage: 1,   //当前页码
                pageSize: 10   //每页记录条数
            };
            //重置日期选择器
            this.applyTime = [];
            this.onSearch()
        },
        // 订单状态--TRAIN
        getStatusToClass(val) {
            //订单状态0=全部 1=待确认 2=待支付 3=待出票 4=出票完成 5=出票失败 8=取消中 6=已取消 7=出票中 10=超标审批中
            if (val === 10 || val === 1 || val === 2 || val === 3) {
                return 'yellow-text'
            } else if (val === 4 || val === 7) {
                return 'green-text'
            } else {
                return 'red-text'
            }
        },
        // 查看详情--TRAIN
        handleDetailClick(val) {
            this.$router.push({
                name: 'admin-order-center-train-order-details',
                query: {
                    orderNo: val.orderNo,
                    type: 'orderCenter'
                }
            })
        },
        //切换
        tabClick(data){
            this.orderStatusData = Number(data.name);
        },
        //下载
        downloadOrderExcel() {
            if (!this.canDownload) {
                return
            }
            this.canDownload = false;
            this.searchForm.orderStatus = this.orderStatusData;
            consumer_trains_order_trainOrderListCenterExport(this.searchForm).then(res => {
                let url = res.datas.url;
                console.log('url', url);
                if (!url && url === '') {
                    this.$message({
                        type: "warning",
                        message: '下载失败，请稍候再试'
                    });
                    return
                }
                window.open(url, "_blank");
            }).finally(() => {
                this.canDownload = true
            })
        },
    }
}
